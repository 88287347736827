<template>
  <section class="AmbulanciasMain empresas__inner container mt24 mb24">
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Registro</h2>
      <div class="empresas_home__text">
        Para poder registrarte es importante ingreses correctamente tu R.F.C. y
        correo electrónico ya que los usarás para iniciar sesión.
      </div>
    </header>
    <DonadoresForm
      :simple="false"
      showPasswordField
      buttonText="Registrarme"
      esRegistro="si"
      @saved="_registroDonadorCompleto"
      @msg="$emit('msg', $event)"
      @httpError="$emit('httpError', $event)"
    />
  </section>
</template>
<script>
export default {
  name: "EmpresasRegistro",
  components: {
    DonadoresForm: () => import("@/components/donadores/DonadoresForm"),
  },
  data() {
    return { cargando: false };
  },
  methods: {
    _registroDonadorCompleto(data) {
      this.$emit(
        "msg",
        "Has sido registrado correctamente. Ahora podrás iniciar sesión con el correo electrónico y contraseña que capturaste"
      );
      this.$router.push("/empresas/login/" + data.email_dco);
    },
    _doLoginDonador() {
      this.cargando = true;
      this.$http
        .post("login/donadores", {
          a: this.u,
          b: this.p,
          t: this.t,
        })
        .then(
          (res) => {
            this.cargando = false;
            if (res.status === 200 && "token" in res.body) {
              this.$session.start();
              this.$headers.headers.Token = null;
              this.$headers.headers.Token = res.body.token;
              if (this.t === "donador") {
                this.$session.set("nombre", res.body.contacto_dco);
                this.$session.set("donador", res.body.razon_social_don);
                this.$session.set("tipo", "donador");
                this.$router.push("/empresas");
              } else if (this.t === "alumno") {
                this.$session.set("nombre", res.body.nombre);
                this.$session.set("tipo", "alumno");
                this.$router.push("/escuela");
              }
              this.$session.set("token", res.body.token);
              this.$emit("msg", "Hola " + res.body.contacto_dco);
              this.$emit("session", true);
            } else {
              this.$emit("httpError", res);
            }
          },
          (err) => {
            this.cargando = false;
            this.$emit("httpError", res);
          }
        );
    },
  },
  created() {
    this.$emit("visible", false);
  },
};
</script>